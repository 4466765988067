// deps
import { useEffect, useRef } from "react";

// helpers
import { roomHasLastSeenRoom } from "@cloudspire/legacy-resources/src/helpers/room";

function HasSeenRoom(props) {
  const { preventRoomReference, roomToAdd, children } = props;

  const $mounted = useRef(false);
  useEffect(function () {
    $mounted.current = true;
    return function () {
      $mounted.current = false;
    };
  }, []);

  return (
    <>
      {$mounted.current &&
        roomHasLastSeenRoom({ preventRoomReference, roomToAdd }) &&
        children}
    </>
  );
}

export default HasSeenRoom;
