// dependencies
import React, { useState, useRef, useEffect } from "react";
import { StyleSheet, View, findNodeHandle } from "react-native";
import { useIntl } from "react-intl";

// components
import TextInput from "@cloudspire/legacy-resources/src/components/TextInput2";
import Popover3 from "@cloudspire/legacy-resources/src/components/Popover3";
import { Row, Col } from "@cloudspire/legacy-resources/src/components/Grid";
import { IconCrossO, IconDatesO } from "@cloudspire/legacy-svg-icon-library";

// containers
import HeadlessDatePicker from "@cloudspire/legacy-resources/src/containers/HeadlessDatePicker";

// libraries
import EnhancedDate from "@cloudspire/legacy-resources/src/libraries/EnhancedDate";

type PropsType = {
  name: string;
  initialDate: EnhancedDate;
  min?: EnhancedDate;
  start: EnhancedDate;
  end: EnhancedDate;
  startInputProps?: any;
  endInputProps?: any;
  onSelect: (event: {
    target: { name: string; value: { start; end } };
  }) => void;
};

const styles = StyleSheet.create({
  headlessDatePickerWrapper: { width: "100%" },
});

function getInitialDate({ start, end, initialDate }) {
  if (null === start && null === end) {
    return initialDate.startOfDays();
  } else if (null !== start) {
    return start.startOfDays();
  }
}

const HeadlessDatePickerWithTextInput: React.FunctionComponent<PropsType> = (
  props
) => {
  const {
    initialDate,
    name,
    start,
    end,
    min,
    startInputProps,
    endInputProps,
    onSelect,
  } = props;

  const [referenceElement, setReferenceElement] = useState(null);

  const [date, setDate] = useState(getInitialDate({ start, end, initialDate }));

  const intl = useIntl();

  const $checkinField = useRef<any>();

  const $checkoutField = useRef<any>();

  const $content = useRef<View>();

  const $view = useRef<View>();

  /**
   * Gère la modification du mois affiché (correspond au moi affiché sur le calendrier le plus à gauche si plusieurs panneaux sont ouverts)
   * @param {EnhancedDate} newDate
   */
  function handleChangeDate(newDate) {
    setDate(newDate);
  }

  useEffect(() => {
    /**
     * Gère le focus sur le champ de la date d’entrée
     * @param {FocusEvent } event
     */
    function handleFocusCheckin(event: FocusEvent) {
      event.stopPropagation();
      setDate(getInitialDate({ start, end, initialDate }));
      setReferenceElement(findNodeHandle($checkinField.current));
    }

    /**
     * Gère le clic sur le champ de la date de sortie
     * @param {FocusEvent} event
     */
    function handleFocusCheckout(event: FocusEvent) {
      event.stopPropagation();
      setReferenceElement(findNodeHandle($checkoutField.current));
    }

    const checkin = findNodeHandle($checkinField.current);
    const checkout = findNodeHandle($checkoutField.current);

    if (checkin instanceof HTMLElement && checkout instanceof HTMLElement) {
      checkin.addEventListener("focus", handleFocusCheckin);
      checkout.addEventListener("focus", handleFocusCheckout);
    }

    return function () {
      if (checkin instanceof HTMLElement && checkout instanceof HTMLElement) {
        checkin.removeEventListener("focus", handleFocusCheckin);
        checkout.removeEventListener("focus", handleFocusCheckout);
      }
    };
  }, [start, end, initialDate]);

  /**
   * Gère le clic sur le bouton "Appliquer" du calendrier
   */
  function handlePressApply() {
    setReferenceElement(null);
  }

  /**
   * Gère la sélection des dates
   * @param {object} param0
   * @param {EnhancedDate} param0.start
   * @param {EnhancedDate} param0.end
   */
  function handleSelect({ start, end }) {
    if (null !== start && null === end) {
      $checkoutField.current.focus();
    }

    onSelect({ target: { name, value: { start, end } } });
  }

  /**
   * Gère le clic sur le bouton pour vider le date d’entrée
   */
  function handleClearStart() {
    onSelect({ target: { name, value: { start: null, end: null } } });
  }

  /**
   * Gère le clic sur le bouton pour vider la date de sortie
   */
  function handleClearEnd() {
    onSelect({ target: { name, value: { start, end: null } } });
  }

  useEffect(() => {
    /**
     * Gère le clic sur la page afin de décider s’il faut fermer le calendrier ou non
     * @param {FocusEvent} event
     */
    function handleClick(event) {
      if (null !== referenceElement) {
        const checkin = findNodeHandle($checkinField.current);
        const checkout = findNodeHandle($checkoutField.current);
        const content = findNodeHandle($content.current);

        if (
          !checkin.contains(event.target as HTMLElement) &&
          !checkout.contains(event.target as HTMLElement) &&
          !content.contains(event.target as HTMLElement) &&
          !checkin.contains(document.activeElement) &&
          !checkout.contains(document.activeElement) &&
          !content.contains(document.activeElement)
        ) {
          setReferenceElement(null);
        }
      }
    }

    if (referenceElement) {
      document.body.addEventListener("click", handleClick);
    }

    return function () {
      document.body.removeEventListener("click", handleClick);
    };
  }, [referenceElement]);

  const $wrapper = useRef();

  return (
    <>
      <Popover3
        visible={null !== referenceElement}
        reference={$wrapper.current}
        usePreventOverlow={true}
        popover={
          <View ref={$content} style={styles.headlessDatePickerWrapper}>
            <HeadlessDatePicker
              min={min}
              date={date}
              start={start}
              end={end}
              shouldRenderApply={true}
              onSelect={handleSelect}
              onChangeDate={handleChangeDate}
              onPressApply={handlePressApply}
            />
          </View>
        }
        container={
          <div ref={$wrapper}>
            <View ref={$view} style={{ width: "100%" }}>
              <Row
                breakpointList={{ sm: 700, md: Infinity }}
                col={{ sm: 1, md: 2 }}
                gutter={0.5}
              >
                <Col>
                  <TextInput
                    ref={$checkinField}
                    {...startInputProps}
                    name="checkin"
                    id="formCheckin"
                    readOnly={true}
                    value={
                      start
                        ? intl.formatDate(start.getNativeDate(), {
                            weekday: "long",
                            month: "short",
                            day: "numeric",
                          })
                        : ""
                    }
                    rightIcon={
                      <div onClick={handleClearStart}>
                        <IconCrossO />
                      </div>
                    }
                    leftIcon={<IconDatesO />}
                    label={intl.formatMessage({
                      id: "app.global.checkin",
                    })}
                  />
                </Col>

                <Col>
                  <TextInput
                    ref={$checkoutField}
                    {...endInputProps}
                    name="checkout"
                    id="formCheckout"
                    readOnly={true}
                    value={
                      end
                        ? intl.formatDate(end.getNativeDate(), {
                            weekday: "long",
                            month: "short",
                            day: "numeric",
                          })
                        : ""
                    }
                    rightIcon={
                      <div onClick={handleClearEnd}>
                        <IconCrossO />
                      </div>
                    }
                    leftIcon={<IconDatesO />}
                    label={intl.formatMessage({
                      id: "app.global.checkout",
                    })}
                  />
                </Col>
              </Row>
            </View>
          </div>
        }
      />
    </>
  );
};

export default HeadlessDatePickerWithTextInput;
