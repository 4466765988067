// dependencies
import React, { useState, useCallback } from "react";
import {
  View,
  TouchableWithoutFeedback,
  StyleSheet as RNStyleSheet,
} from "react-native";
import { useIntl } from "react-intl";
import { StyleSheet } from "aphrodite";

// components
import Stack from "@cloudspire/legacy-resources/src/components/Stack";
import Button from "@cloudspire/legacy-resources/src/components/Button2";
import {
  IconCaretLeftO,
  IconCaretRightO,
} from "@cloudspire/legacy-svg-icon-library";

// containers
import { DatePickerYear } from "@cloudspire/legacy-resources/src/components/DatePicker";
import DatePickerDay from "./DatePickerDay";

// contexts
import { DatePickerProvider } from "./datePicker";

// hocs
import useViewportMedia from "@cloudspire/legacy-resources/src/hocs/useViewportMedia";

// libraries
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";
import { noop } from "@cloudspire/legacy-shared/src/libraries";
import EnhancedDate from "@cloudspire/legacy-resources/src/libraries/EnhancedDate";

const styles = StyleSheet.create({
  apply: {
    paddingTop: "0.3125rem",
    paddingBottom: "0.3125rem",
  },
});

const rnStyles = RNStyleSheet.create({
  datePickerWrapper: {
    padding: emToPx(1),
    borderStyle: "solid",
    borderColor: "#eee",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderRadius: 3,
  },
  arrow: {
    position: "absolute",
    top: 16,
    cursor: "pointer",
  },
  leftArrow: {
    left: 5,
  },
  rightArrow: {
    right: 5,
  },
});

type PropsType = {
  date: EnhancedDate;
  start: EnhancedDate;
  end: EnhancedDate;
  min?: EnhancedDate;
  shouldRenderApply?: boolean;
  onChangeDate?: (newDate: EnhancedDate) => void;
  onSelect?: ({ start, end }) => void;
  onPressApply?;
};

const HeadlessDatePicker: React.FunctionComponent<PropsType> = (props) => {
  const {
    date,
    start,
    end,
    min = null,
    shouldRenderApply = false,
    onChangeDate = noop,
    onSelect = noop,
    onPressApply = noop,
  } = props;

  const intl = useIntl();

  const { matches } = useViewportMedia({
    queries: [
      {
        minWidth: 800,
      },
    ],
  });

  const from = date.clone().setDate(1);

  const to = from
    .clone()
    .addMonth(matches ? 1 : 0)
    .setDateToLastOfMonth();

  const [hover, setHover] = useState(null);

  const handleChangeSelect = useCallback(
    (selectedDate: EnhancedDate) => {
      let newStart = start;
      let newEnd = end;

      if (null === start) {
        newStart = selectedDate;
      } else if (null === end) {
        newEnd = selectedDate;
      } else {
        newStart = selectedDate;
        newEnd = null;
      }

      onSelect({ start: newStart, end: newEnd });
    },
    [start, end, onSelect]
  );

  const handleChangeHover = setHover;

  return (
    <DatePickerProvider
      value={{
        DatePickerDay,
        start,
        end,
        hover,
        min,
        onChangeSelect: handleChangeSelect,
        onChangeHover: handleChangeHover,
      }}
    >
      <View
        style={[
          rnStyles.datePickerWrapper,
          {
            minWidth: matches && !from.isEqualTo(to) ? emToPx(30) : null,
          },
        ]}
      >
        <View style={{ position: "relative" }}>
          <DatePickerYear from={from} to={to} />

          <TouchableWithoutFeedback
            hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
            onPress={() => onChangeDate(from.clone().addMonth(-1))}
          >
            <View style={[rnStyles.arrow, rnStyles.leftArrow]}>
              <IconCaretLeftO />
            </View>
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback
            hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
            onPress={() => onChangeDate(from.clone().addMonth(1))}
          >
            <View style={[rnStyles.arrow, rnStyles.rightArrow]}>
              <IconCaretRightO />
            </View>
          </TouchableWithoutFeedback>

          {shouldRenderApply && (
            <>
              <Stack marginTop={1} />

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <Button
                    label={intl.formatMessage({
                      id: "app.component.datePicker.apply",
                    })}
                    appearance="secondary"
                    aStyle={styles.apply}
                    onPress={onPressApply}
                  />
                </div>
              </div>
            </>
          )}
        </View>
      </View>
    </DatePickerProvider>
  );
};

export default HeadlessDatePicker;
